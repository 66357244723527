import _objectSpread from "F:/@company/lzz-productcheck-front/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
import { mapState } from "vuex";
export default {
  data: function data() {
    return {};
  },
  created: function created() {},
  watch: {// checkUrl(){
    //   this.iframeLoad()
    // }
  },
  mounted: function mounted() {},
  methods: {// iframeLoad(){
    //   const iframe = document.getElementById('iframeID')
    //   iframe.src= this.checkUrl
    // },
    // iframeError(){
    // }
  },
  computed: _objectSpread({}, mapState({
    checkUrl: function checkUrl(state) {
      return state.iframeCheck.checkUrl;
    }
  }))
};