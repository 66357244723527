var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "iframeCheck" } }, [
    _c("iframe", {
      attrs: {
        id: "iframeID",
        src: _vm.checkUrl,
        scrolling: "auto",
        frameborder: "0"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }